import React from 'react'
import { css } from 'glamor'
import Img from 'gatsby-image'
import ReactPlayer from 'react-player'

import ScrollWaypoint from './scrollWaypoint'
import AnimatedClipPath from './animatedClipPath';

const Project = ({ id, elementStyle, waypoint, title, image, video, caption, captionStyle, clipPath, clipPathId }) => (
    <div id={id} className={`${projectStyle} ${elementStyle}`}>
      <ScrollWaypoint waypoint={waypoint}>
        {video ?
          <figure className={`${projectVideoStyle}`}>
            <div className='project-media' role='img' aria-label={title}>
              <div className={`clippedObjectContainer ${clippedObjectStyle} ${clipPathId && css({clipPath: 'url(#' + clipPathId + ')'})}`}>
                <div className={`${videoContainerStyle} ${css({paddingTop: image ? 75 + '%' : '100%'})}`}>
                  <ReactPlayer className={`${reactPlayerStyle} ${clippedObjectStyle} ${css({background: 'url(' + image.base64 + ') center / cover no-repeat'})}`} playing={true} loop={true} muted={true} width={'100%'} height={'100%'} url={video} config={{file: {attributes: {poster: image && image.src}}}}/>
                </div>
              </div>
              <div className={`clippedObjectFallbackContainer ${clippedObjectFallbackContainerStyle}`}>
                <svg className={`${clippedObjectFallbackStyle}`} viewBox={image ? '0 0 100 75' : '0 0 100 75'}  /* Add video aspect ratio here! viewBox={"0 0 100 " + 100 / image.aspectRatio} and remeber to add image fallback after foreignobject*/ >
                  <switch>
                    <foreignObject width='100%' height='100%' className={`${clippedObjectStyle} ${clipPathId && css({clipPath: 'url(#' + clipPathId + ')'})}`} requiredExtensions='http://www.w3.org/1999/xhtml'>
                      <div className={`${videoContainerStyle} ${css({paddingTop: '75%'})}`}>
                        <ReactPlayer className={`${reactPlayerStyle} ${clippedObjectStyle} ${css({background: 'url(' + image.base64 + ') center / cover no-repeat'})}`} playing={true} loop={true} muted={true} width={'100%'} height={'100%'} url={video} config={{file: {attributes: {poster: image && image.src}}}}/>
                      </div>
                    </foreignObject>
                    {image && <image xlinkHref={image.src} width={100} height={100 / image.aspectRatio}/>}
                  </switch>
                </svg>
              </div>
            </div>
            {caption && <figcaption className={`${defaultCaptionStyle} ${captionStyle}`}>{caption}</figcaption>}
            <div className={`${clipPathStyle}`} aria-hidden='true'>
              <AnimatedClipPath>
                {clipPath}
              </AnimatedClipPath>
            </div>
          </figure>
        : image ?
          <>
            {clipPath ?
              <figure className={`${projectImageStyle}`} >
                <div className='project-media' role='img' aria-label={title}>
                  <div className={`clippedObjectContainer ${clippedObjectStyle}`} {...css(clipPathId && {clipPath: 'url(#' + clipPathId + ')'})}>
                    <Img className={`${clippedImageStyle}`}  fluid={image} alt={title}/>
                  </div>
                  <div className={`clippedObjectFallbackContainer ${clippedObjectFallbackContainerStyle}`}>
                    <svg className={`${clippedObjectFallbackStyle}`} viewBox={'0 0 100 ' + 100 / image.aspectRatio} >
                      <switch>
                        <foreignObject width='100%' height='100%' className={`${clippedObjectStyle}`}  {...css(clipPathId && {clipPath: 'url(#' + clipPathId + ')'})} requiredExtensions='http://www.w3.org/1999/xhtml'>
                          <Img className={`${clippedImageStyle}`}  fluid={image} alt={title} />
                        </foreignObject>
                        <image xlinkHref={image.src} width={100} height={100 / image.aspectRatio}/>
                      </switch>
                    </svg>
                  </div>
                </div>
                {caption && <figcaption className={`${defaultCaptionStyle}`} {...captionStyle}>{caption}</figcaption>}
                <div className={`${clipPathStyle}`}  aria-hidden='true'>
                  <AnimatedClipPath>
                    {clipPath}
                  </AnimatedClipPath>
                </div>
              </figure>
              :
              <figure className={`${projectImageStyle}`} >
                <Img className={`${clippedImageStyle}`}  fluid={image} alt={title}/>
                {caption && <figcaption className={`${defaultCaptionStyle} ${captionStyle}`}>{caption}</figcaption>}
              </figure>
            }
          </>
        : 
          <p>No project media</p>
        }
      </ScrollWaypoint>
    </div>
)

export default Project

let projectStyle = css({
  label: 'project',
  display: 'flex',
  margin: '8em 0',
  ':first-of-type': {
    marginTop: 0
  }
})

let projectImageStyle = css({
  label: 'projectImage',
  flex: 1,
  margin: 0,
  position: 'relative',
  pointerEvents: 'none'
})

let projectVideoStyle = css({
  label: 'projectVideo',
  flex: 1,
  margin: 0,
  position: 'relative'
})

let videoContainerStyle = css({
  label: 'videoContainer',
  position: 'relative'
})

let reactPlayerStyle = css({
  label: 'reactPlayer',
  position: 'absolute',
  top: 0,
  left: 0,
})

let defaultCaptionStyle = css({
  label: 'caption',
  padding: '0 5%',
  fontSize: '0.6875em',
  color: '#333',
  pointerEvents: 'all',
  '& > *:first-child': {
    marginTop: 0
  },
  '& > *': {
    padding: 'initial',
    maxWidth: '32em',
    boxSizing: 'border-box',
  },
  '& a': {
    fontWeight: 'bold',
    opacity: 0.8125,
    transition: 'opacity 0.5s',
    ':hover': {
      opacity: 1
    }
  },
  '& h3': {
    fontSize: '1.125em',
    fontWeight: 'bold',
    letterSpacing: '0.175em',
    textTransform: 'uppercase',
    margin: '0 2em 0.333em 0',
    color: 'var(--highlight, #fff)',
    cursor: 'pointer',
    opacity: 0.8125,
    transition: 'opacity 0.5s, color 0.75s',
    ':hover': {
      opacity: 1
    },
    '& + p': {
      marginTop: 0
    },
  }
})

let clippedObjectStyle = css({
  label: 'clippedObject',
  willChange: 'clip-path'
})

let clippedObjectFallbackContainerStyle = css({
  label: 'clippedObjectFallbackContainer',
  display: 'none'
})

let clippedObjectFallbackStyle = css({
  label: 'clippedObjectFallback',
  width: '100%',
  height: '100%',
})

let clippedImageStyle = css({
  label: 'image',
  width: '100%',
  height: 'auto',
})

let clipPathStyle = css({
  label: 'clipPathContainer',
  position: 'absolute',
  top: 0,
  width: 0,
  height: '100%',
  margin: 0
})

const msClipPathFallbackStyle = `
  @supports (-ms-ime-align:auto) {
    .clippedObjectFallbackContainer {
      display: flex;
    }
    .clippedObjectContainer {
      display: none;
    }
  }
`

css.insert(msClipPathFallbackStyle);