import React, {Component} from 'react'
import { connect } from 'react-redux'
import { css } from 'glamor'
import { scroller } from 'react-scroll'
import LinkSpan from '../components/linkSpan'
import { Button } from '@rmwc/button';

import '@material/button/dist/mdc.button.css'

class SelectedWorksHeader extends Component {
  constructor(props, context) {
    super(props, context);
    
    this._onClick = (e) => {
      e.currentTarget.blur();
      scroller.scrollTo('selectedWorks', {
        smooth: true,
        duration: 1000,
        delay: 100,
        offset: this.svgSelectedHeaderRef.clientHeight,
      });
    }

    this.state = {
      initialized: false
    }
  }

  render() {
    const { children, selectedWorksHeader } = this.props;
    return(
      <h2 id='selectedWorks' className={`${selectedWorksHeaderStyle} ${!selectedWorksHeader ? hiddenSelectedWorksHeaderStyle : this.state.initialized && visibleSelectedWorksHeaderStyle}`} ref={node => this.svgSelectedHeaderRef = node}>
        <Button className={`${selectedWorksButtonStyle}`} onClick={this._onClick} onFocus={this._onFocus}>
          <span className={`${arrowStyle}`}>&darr;</span>
          <span className={`${selectedWorksTextStyle}`}><LinkSpan>{children}</LinkSpan>{children}</span>
        </Button>
      </h2>
    );
  }

  shouldComponentUpdate(nextProps) {
    const { selectedWorksHeader } = this.props;
    if(selectedWorksHeader !== nextProps.selectedWorksHeader) {
      this.setState({initialized: true})
      return true;
    } else {
      return false;
    }
  }
}

SelectedWorksHeader.defaultProps = {
  selectedWorksHeader: true
}
  
const mapStateToProps = (state, ownProps) => {
  return {
    selectedWorksHeader: state.Waypoint.selectedWorksHeader,
  };
}

export default connect(mapStateToProps)(SelectedWorksHeader);


let fadeoutAnimation = css.keyframes({ 
  '0%': {transform: 'translateY(0em); opacity: 1'},
  '100%': { transform: 'translateY(0.5em); opacity: 0'}
})

let fadeinAnimation = css.keyframes({ 
  '0%': {transform: 'translateY(0.5em); opacity: 0'},
  '100%': {transform: 'translateY(0em); opacity: 1'}
})

let selectedWorksHeaderStyle = css({
  label: 'selectedWorksHeader',
  display: 'flex',
  minHeight: '10vh',
  fontSize: 'inherit',
  fontWeight: '400',
  margin: '0 0 1em -0.5em',
  padding: '0 5%',
  paddingBottom: 'var(--bottom-safe-area)',
  alignItems: 'center',

})

let selectedWorksButtonStyle = css ({
  '&.mdc-button': {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontWeight: '400',
    padding: '0 0.5em',
    textTransform: 'none',
    borderRadius: '3em',
    opacity: 1,
    '&:not(:disabled)': {
      color: 'var(--highlight, #fff)',
    }
  }
})

let selectedWorksTextStyle = css({
  fontSize: '0.6875em',
  verticalAlign: '0.25em',
  letterSpacing: '0.1875em',
  textTransform: 'uppercase',
})

let visibleSelectedWorksHeaderStyle = css({
  animation: `${fadeinAnimation} 1s`,
  animationTimingFunction: 'ease-in-out',
  animationFillMode: 'forwards',
})

let hiddenSelectedWorksHeaderStyle = css({
  animation: `${fadeoutAnimation} 1s`,
  animationTimingFunction: 'ease-in-out',
  animationFillMode: 'forwards',
  pointerEvents: 'none',
})

let hoveringAnimation = css.keyframes({ 
  '0%': { transform: 'translateY(0.03em)'},
  '50%': { transform: 'translateY(-0.175em)'},
  '100%': { transform: 'translateY(0.03em)' }
})

let arrowStyle = css({
  label: 'arrow',
  display: 'inline-block',
  marginRight: '0.5em',
  animation: `${hoveringAnimation} 1.5s infinite`,
  animationTimingFunction: 'ease-in-out',
  color: 'var(--highlight, #fff)'
})