import React from 'react'
import { css } from 'glamor'
import ScrollWaypoint from './scrollWaypoint'
import LinkSpan from './linkSpan'
import { ReactComponent as Logo } from '../images/logo.svg'
import {ReactComponent as MailIcon} from '../images/round-email-24px.svg'
import {ReactComponent as PhoneIcon} from '../images/round-phone-24px.svg'
import AnimatedLogo from './animatedLogo'
//import LogoMask from './logoMask'

const Header = ({ waypoint }) => (
  <header id='header' className={`${headerStyle}`} role='banner'>
    <ScrollWaypoint waypoint={waypoint}>
      <div className={`${contentStyle}`}>
        <h1 className={`${titleStyle}`}>
          <div className={`${logoContainerStyle}`}>
            <AnimatedLogo className={`${logoStyle}`}>
              <Logo title='Johan Bisse Mattsson' className={`${logoStyle}`} role='heading' aria-level='1' aria-labelledby='logo-title' />
            </AnimatedLogo>
          </div>
          &amp; the in-betweens of graphic design, <span className={`${noWrapStyle}`}>illustration and coding</span>
        </h1>
        <address className={`${contactStyle}`}>
          <a href='mailto:johan@johanbissemattsson.se' className={`${contactLinkStyle}`}><span className={`icon ${iconStyle}`}><MailIcon fill='var(--color)' width='0.75em' height='0.75em'/></span><LinkSpan>johan@johanbissemattsson.se</LinkSpan>johan@johanbissemattsson.se</a>
          <a href='tel:+46708209991' className={`${contactLinkStyle}`}><span className={`icon ${iconStyle}`}><PhoneIcon fill='var(--color)' width='0.75em' height='0.75em'/></span><LinkSpan>+46-708-209991</LinkSpan>+46-708-209991</a>
        </address>
      </div>
    </ScrollWaypoint>
  </header>
)

export default Header;

let headerStyle = css({
  label: 'header',
  display: 'flex',
  minHeight: '90%',
})

let contentStyle = css({
  label: 'content',
  flex: 1,
  alignSelf: 'center',
  padding: '0 5%',
})

let titleStyle = css({
  label: 'title',
  margin: 0,
  fontSize: '1em',
  fontWeight: 'normal',
  color: '#333'
})

let logoContainerStyle = css({
  label: 'logoContainer',
  display: 'block',
  margin: '2.5% 0 1.5em 0',
  '& svg path': {
    transition: 'stroke 0.75s'
  }

})

let logoStyle = css({
  label: 'logo',
  display: 'block',
  width: '75%',
  maxWidth: '640px',
  minWidth: '64px',
  height: '100%',
  minHeight: '64px'

})

let contactStyle = css({
  label: 'contact',
  margin: '1em 0',
  fontStyle: 'normal',
  '& a': {
    display: 'table',
    position: 'relative',
    textDecoration: 'none',
    color: '#333',
    overflow: 'hidden',
  }
})

let contactLinkStyle = css({
  label: 'contactLink',
  ':before': {
    content: '\\00a0\\00a0',
    opacity: '0',
  }
})

let iconStyle = css({
  label: 'icon',
  position: 'absolute',
  left: '0',
  top: '1px',
  width: '0.75em',
  height: '100%',
})

let noWrapStyle = css({
  label: 'noWrap',
  whiteSpace: 'nowrap'
})
