import React, {PureComponent} from 'react'
import { css } from 'glamor'
import { animateScroll } from 'react-scroll'

class CharacterDraggable extends PureComponent {
  constructor(props, context) {
    super(props, context);

    let scrollWheelTimer;

    this.state = {
      pointerEvents: true,
      initialDragPosition: {x: 0, y: 0},
    }

    this._removeSelections = () => {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      } else if (document.selection) {
        document.selection.empty();
      }
    }

    this._resetDragPositions = () => {
      this.setState({
        initialDragPosition: {x: 0, y: 0},
      });
    }

    this._onMouseDown = (event) => {
      event.preventDefault();
      this._removeSelections();
      this.setState({
        initialDragPosition: {
          x: event.clientX, 
          y: event.clientY
        }
      });

      // Add event listeners for mousemove and mouseup only when mousedown
      document.addEventListener('mousemove', _onMouseMove);
      document.addEventListener('mouseup', _onMouseUp);
    }

    const _onMouseMove = (event) => {
      event.stopPropagation();
      this.props.updateCharacterProperties({
        x: event.clientX - this.state.initialDragPosition.x,
        y: event.clientY - this.state.initialDragPosition.y
      });
    }

    const _onMouseUp = (event) => {
      // Remove event listeners for mousemove and mouseup while mouseup
      document.removeEventListener('mousemove', _onMouseMove);
      document.removeEventListener('mouseup', _onMouseUp);
      
      this._resetDragPositions();
      this.props.updateCharacterProperties({
        x: 0,
        y: 0
      });
    }
    
    this._onTouchStart = (event) => {
      this._removeSelections();
      this.setState({
        initialDragPosition: {
          x: event.touches[0].clientX,
          y: event.touches[0].clientY}
      });

      document.addEventListener('touchmove', this._onTouchMove);
      document.addEventListener('touchend', this._onTouchEnd);
    }

    this._onTouchMove = (event) => {
      this.props.updateCharacterProperties({
        x: event.touches[0].clientX - this.state.initialDragPosition.x,
        y: event.touches[0].clientY - this.state.initialDragPosition.y
      });
    }

    this._onTouchEnd = () =>  {
      document.removeEventListener('touchmove', this._onTouchMove);
      document.removeEventListener('touchend', this._onTouchEnd);      
      this._resetDragPositions();
      this.props.updateCharacterProperties({
        x: 0,
        y: 0
      });
    }

    this._onDoubleClick = () => {
      animateScroll.scrollToTop();
    }

    this._allowPointerEvents = () => {
      this.setState({pointerEvents: true})
    }

    this._onWindowScroll = () => {
      clearTimeout(scrollWheelTimer);
      scrollWheelTimer = setTimeout(this._allowPointerEvents, 250);
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this._onWindowScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this._onWindowScroll);
  }

  render() {
    return (
    <div
      className={`${characterInteractableStyle}`}
      onTouchStart={this._onTouchStart}
      onMouseDown={this._onMouseDown}
      onDoubleClick={this._onDoubleClick}
      style={{pointerEvents: this.state.pointerEvents ? 'all' : 'none'}}
    >
      {this.props.children}
    </div>
    )
  }
}

export default CharacterDraggable;

let characterInteractableStyle = css({
  label: 'characterInteractable',
  cursor: 'grab',
  ':active': {
    cursor: 'grabbing'
  },
  borderRadius: '100%',
})
