import React from 'react'
import { css } from 'glamor'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import SelectedWorksHeader from '../components/selectedWorksHeader'
import Project from '../components/project'
import Character from '../components/character'
import LinkSpan from '../components/linkSpan'
import LinkHeader from '../components/linkHeader'
import {ReactComponent as ClipPathKonstfack} from '../images/konstfack.clippath.svg'
import {ReactComponent as ClipPathFlux} from '../images/flux.clippath.svg'
import {ReactComponent as ClipPathAvstandetsBla} from '../images/avstandetsbla.clippath.svg'
import {ReactComponent as ClipPathWebsites} from '../images/websites.clippath.svg'

import TestVideo from '../videos/avstandetsbla.mp4';

const IndexPage = ({ data }) => (
  <StaticQuery
    query={graphql`
      query ImageQuery {
        konstfackImage: imageSharp(fluid: {originalName: { eq: "konstfack.jpg" }}) {
          id
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        },
        fluxImage: imageSharp(fluid: {originalName: { eq: "flux.jpg" }}) {
          id
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        },        
        avstandetsBlaImage: imageSharp(fluid: {originalName: { eq: "avstandetsbla.jpg" }}) {
          id
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        },
        websitesImage: imageSharp(fluid: {originalName: { eq: "websites4.jpg" }}) {
          id
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }        
      },
    `}
    
    render={data => (
      <Layout>
        <Header
          waypoint={{
            character: {
              translate: {x: 21, y: -7},
              lookAt: {x: -23, y: 4},
              scale: 1,
            },
            selectedWorksHeader: true,
            background: '#3399ff'
          }}
        />
        <Character />
        <div id='selectedWorks'>
          <SelectedWorksHeader>
            Selected works
          </SelectedWorksHeader>
          <Project
            title='Konstfack'
            id='konstfack'
            image={data.konstfackImage.fluid}
            caption={
              <><LinkHeader to='konstfack'>KONSTFACK</LinkHeader><p>Weaved curtain for <a href='https://www.konstfack.se/sv/Aktuellt/Nyheter-och-pressmeddelanden/2018/Konstfacks-historiska-tradar-vavs-samman/'><LinkSpan>Konstfack University of Arts,</LinkSpan>Konstfack University of Arts, <LinkSpan>Crafts and Design</LinkSpan>Crafts and Design</a>, Stockholm 2018. Made together with <a href='https://www.uglycute.com'><LinkSpan>UglyCute</LinkSpan>UglyCute</a> and <a href='https://historicaltextiles.org/about-us/'><LinkSpan>Amica Sundström</LinkSpan>Amica Sundström</a>.</p></>
            }
            captionStyle={{...testCaptionStyle}}
            clipPath={<ClipPathKonstfack/>}
            clipPathId={'clippath-konstfack'}
            elementStyle={konstfackProjectStyle}
            waypoint={{
              character: {
                translate: {x: -15, y: 0},
                lookAt: {x: 33, y: 0},
                scale: 0.75,
                lookingAway: true
              },
              background: '#33cc99'
            }}
          />
          <Project
            title='Wallpaper for Flux'
            id='flux'
            image={data.fluxImage.fluid}
            caption={
              <><LinkHeader to='flux'>Flux: Parian Unpacked</LinkHeader><p>Wallpapers for curator and artist <a href='http://mattjsmith.com/'><LinkSpan>Matt Smith</LinkSpan>Matt Smith</a>'s installation/exhibition <a href='https://www.fitzmuseum.cam.ac.uk/calendar/whatson/matt-smiths-flux-parian-unpacked' {...noWrap}><em><LinkSpan>Flux: Parian Unpacked</LinkSpan>Flux: Parian Unpacked</em></a> at <a href='https://www.fitzmuseum.cam.ac.uk/' {...noWrap}><LinkSpan>The Fitzwilliam Museum</LinkSpan>The Fitzwilliam Museum</a>, Cambridge, 2018.</p></>
            }
            captionStyle={{...fluxCaptionStyle}}
            clipPath={<ClipPathFlux/>}
            clipPathId={'clippath-flux'}
            elementStyle={anotherProjectStyle}
            waypoint={{character: { translate: { x: 12, y: 3}, lookAt: {x: -17, y: 12}, scale: 0.875, lookingAway: true}, background: '#e7774a'}} />
          <Project
            title='Avståndets blå / Distance of Blue'
            id='avstandetsbla'
            image={data.avstandetsBlaImage.fluid}
            caption={
              <><LinkHeader to='avstandetsbla'>Avståndets blå</LinkHeader><p>Degree project where the importance of distance was the depature point, resulting in a binocular from which you can explore a virtual illustration world in 360&deg;. Stockholm, 2014.</p></>
            }
            captionStyle={{...avstandetsBlaCaptionStyle}}
            clipPath={<ClipPathAvstandetsBla/>}
            clipPathId={'clippath-avstandetsbla'}
            video={[
                {
                  src: TestVideo,
                  type: 'video/mp4'
                },
              ]}
            elementStyle={anotherProjectStyle}
            waypoint={{character: { translate: { x: -24, y: -3}, lookAt: {x: 7, y: 7}, scale: 1, lookingAway: true}, background: '#3399ff'}} />     
          <Project
            title='Websites'
            id='websites'
            image={data.websitesImage.fluid}
            caption={
              <><LinkHeader to='websites'>Web design <span role='img' aria-label='heart'>❤️</span> web development</LinkHeader><p>Examples of websites which I've been part of, either by doing web design, web development, or both.</p></>
            }
            captionStyle={{...testCaptionStyle}}
            clipPath={<ClipPathWebsites/>}
            clipPathId={'clippath-websites'}            
            elementStyle={anotherProjectStyle}
            waypoint={{character: { translate: { x: 23, y: 3}, lookAt: {x: -12, y: 12}, scale: 0.875, lookingAway: true}, background: '#e7774a'}} />                           
          </div>
        <Footer waypoint={{character: {translate: {x: 0, y: 30}, lookAt: {x: -23, y: 35}, scale: 0.5}, background: '#3399ff'}} />
      </Layout>
    )}
  />
)

export default IndexPage;

let testCaptionStyle = css({
  '@media (min-width: 720px)': {
    bottom: '0',
  }
})

let fluxCaptionStyle = css({
  '@media (min-width: 1140px)': {
    right: '0',
  }
})

let avstandetsBlaCaptionStyle = css({
  '@media (min-width: 640px)': {
    left: '27.5%',
  }
})

let konstfackProjectStyle = css({
  label: 'firstProject',
  width: '100%',
  '& .project-media': {
    //position: 'relative',
    //zIndex: 101
  }
})

let anotherProjectStyle = css({
  label: 'anotherProject',
  width: '100%',
})

let noWrap = css({
  whiteSpace: 'nowrap'
})