import React, {Component} from 'react'
import { scroller } from 'react-scroll'

class LinkHeader extends Component {
  constructor(props, context) {
    super(props, context);
    
    this._onClick = (e) => {
      e.currentTarget.blur();
      scroller.scrollTo(this.props.to, {
        smooth: true,
        duration: 1000,
        delay: 100,
      });
    }
  }

  render() {
    const { children } = this.props;
    return(
      <h3 onClick={this._onClick}>
        {children}
      </h3>
    );
  }
}

export default LinkHeader;