import React, { PureComponent } from 'react'
import {
  Scene,
  WebGLRenderer,
  Color,
  PerspectiveCamera,
  Clock,
  HemisphereLight,
  BufferGeometry,
  BufferAttribute,
  MeshStandardMaterial,
  MeshLambertMaterial,
  Mesh,
  Vector3,
} from 'three'
import { css } from 'glamor'

import Worker from 'worker-loader!../workers/characterLoader.worker';

class CharacterThreeScene extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      updateCanvasSize: false
    }

    this._start = () =>  {
      if (!this.frameId) {
        this.frameId = requestAnimationFrame(this._animate);
      }
    }

    this._stop = () => {
      cancelAnimationFrame(this.frameId);
    }

    this._animate = () => {
      //console.log('animate', this.props.characterLookAtDistance.z);
      const lookAtVector = new Vector3(this.props.characterLookAtDistance.x, this.props.characterLookAtDistance.y, this.props.characterLookAtDistance.z);
      const unprojectedLookAtVector = lookAtVector.unproject(this.camera);
      if(this.props.characterLookAtDistance.z < 0) {
        //this.camera.applyMatrix( new Matrix4().makeRotationY( Math.PI) );
        this.characterMesh.lookAt(
          unprojectedLookAtVector.x,
          -unprojectedLookAtVector.y,
          -unprojectedLookAtVector.z
        ); 
      } else {
        //this.camera.up( 0, 0, 1);
        //this.camera.applyMatrix( new Matrix4().makeRotationY( Math.PI) );

        this.characterMesh.lookAt(
          -unprojectedLookAtVector.x,
          unprojectedLookAtVector.y,
          unprojectedLookAtVector.z
        ); 
      }


      this.propellerMesh.rotateY(this.clock.getDelta() * 12); //multiply with propeller speed

      this._renderScene()
      this.frameId = window.requestAnimationFrame(this._animate)
    }

    this._renderScene = () => {
      this.renderer.render(this.scene, this.camera);
    }

    this._removeWorker = () => {
      if (this.worker) {
        this.worker.removeEventListener('message', this._onReceiveMessage);
        this.worker.terminate();
      }
    }  

    this._onReceiveMessage = (e) => {
      if (e.data === 'error') {
        console.log("Oh noes! Couldn't load character :(");
      } else {
        console.log('Character loaded!');
        this.scene = new Scene();
        this.renderer = new WebGLRenderer({ antialias: true, alpha: true });
        this.renderer.setSize(this.props.canvasSize, this.props.canvasSize);
        this.renderer.setClearColor(new Color(0x000000), 0);
        this.renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);      
        this.camera = new PerspectiveCamera(60, 1, 0.1, 100);
        this.camera.position.set(0, 0, 3);
        this.clock = new Clock();
        this.hemisphereLight = new HemisphereLight();
        this.hemisphereLight.color.set('#FFbd11');
        this.hemisphereLight.groundColor.set('#aa33ff');
        this.scene.add(this.hemisphereLight);

        const characterGeometry = new BufferGeometry();
        characterGeometry.setIndex( new BufferAttribute(e.data.vertexIndexes, 1 ));
        characterGeometry.addAttribute( 'position', new BufferAttribute( e.data.vertexPositions, 3 ));
        characterGeometry.addAttribute( 'normal', new BufferAttribute( e.data.vertexNormals, 3 ));
        const characterMaterial = new MeshStandardMaterial( {color: 0xE768A6, roughness: 0.75, metalness: 0} );
        const characterMesh = new Mesh( characterGeometry, characterMaterial );
        characterMesh.position.set(0,-0.375,0);
        characterMesh.name = 'character';

        const beakGeometry = new BufferGeometry();
        beakGeometry.setIndex( new BufferAttribute(e.data.beakVertexIndexes, 1 ));
        beakGeometry.addAttribute( 'position', new BufferAttribute( e.data.beakVertexPositions, 3 ));
        beakGeometry.addAttribute( 'normal', new BufferAttribute( e.data.beakVertexNormals, 3 ));
        const beakMaterial = new MeshStandardMaterial( {color: 0xffbd11, roughness: 0.75, metalness: 0} );
        const beakMesh = new Mesh( beakGeometry, beakMaterial );
        beakMesh.name = 'beak';
        characterMesh.add(beakMesh);

        const capGeometry = new BufferGeometry();
        capGeometry.setIndex( new BufferAttribute(e.data.capVertexIndexes, 1 ));
        capGeometry.addAttribute( 'position', new BufferAttribute( e.data.capVertexPositions, 3 ));
        capGeometry.addAttribute( 'normal', new BufferAttribute( e.data.capVertexNormals, 3 ));
        const capMaterial = new MeshStandardMaterial( {color: 0xaa33ff, roughness: 0.75, metalness: 0} );
        const capMesh = new Mesh( capGeometry, capMaterial );
        capMesh.name = 'cap';
        characterMesh.add(capMesh);

        const eyesGeometry = new BufferGeometry();
        eyesGeometry.setIndex( new BufferAttribute(e.data.eyesVertexIndexes, 1 ));
        eyesGeometry.addAttribute( 'position', new BufferAttribute( e.data.eyesVertexPositions, 3 ));
        eyesGeometry.addAttribute( 'normal', new BufferAttribute( e.data.eyesVertexNormals, 3 ));
        const eyesMaterial = new MeshStandardMaterial( {color: 0x9911cf, roughness: 0.75, metalness: 0} );
        const eyesMesh = new Mesh( eyesGeometry, eyesMaterial );
        eyesMesh.name = 'eyes';
        eyesMesh.scale.y = 1;
        characterMesh.add(eyesMesh);

        const feetGeometry = new BufferGeometry();
        feetGeometry.setIndex( new BufferAttribute(e.data.feetVertexIndexes, 1 ));
        feetGeometry.addAttribute( 'position', new BufferAttribute( e.data.feetVertexPositions, 3 ));
        feetGeometry.addAttribute( 'normal', new BufferAttribute( e.data.feetVertexNormals, 3 ));
        const feetMaterial = new MeshStandardMaterial( {color: 0xFFBD11, roughness: 0.75, metalness: 0} );
        const feetMesh = new Mesh( feetGeometry, beakMaterial );
        feetMesh.name = 'feet';
        characterMesh.add(feetMesh);


        const propGeometry = new BufferGeometry();
        propGeometry.setIndex( new BufferAttribute(e.data.propVertexIndexes, 1 ));
        propGeometry.addAttribute( 'position', new BufferAttribute( e.data.propVertexPositions, 3 ));
        propGeometry.addAttribute( 'normal', new BufferAttribute( e.data.propVertexNormals, 3 ));
        const propMaterial = new MeshStandardMaterial( {color: 0x9911cf, roughness: 0.75, metalness: 0} );
        const propellerMesh = new Mesh( propGeometry, propMaterial );
        propellerMesh.name = 'propeller';
        characterMesh.add(propellerMesh);

        const swirlGeometry = new BufferGeometry();
        swirlGeometry.setIndex( new BufferAttribute(e.data.swirlVertexIndexes, 1 ));
        swirlGeometry.addAttribute( 'position', new BufferAttribute( e.data.swirlVertexPositions, 3 ));
        swirlGeometry.addAttribute( 'normal', new BufferAttribute( e.data.swirlVertexNormals, 3 ));
        const swirlMaterial = new MeshLambertMaterial( {color: 0x333333, opacity: 0.0} );
        const swirlMesh = new Mesh( swirlGeometry, swirlMaterial );
        swirlMesh.name = 'swirl';
        propellerMesh.add(swirlMesh)
        this.characterGeometry = characterGeometry;
        this.scene.add(characterMesh);
        this.characterMesh = characterMesh;
        this.propellerMesh = propellerMesh;
        this.refCanvasContainer.appendChild(this.renderer.domElement);

        this._start();
        this.renderer.render(this.scene, this.camera);

        this._removeWorker();
        this.props.onCharacterLoaded();
        
        //this.props.dispatch(onCharacterLoaded());
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.canvasSize !== prevProps.canvasSize && this.renderer) {
      this.renderer.setSize(this.props.canvasSize, this.props.canvasSize);
    }
    return true;
  }

  componentDidMount() {
    console.log('Loading character...');
    const worker = new Worker();
    worker.postMessage('loadCharacter');
    worker.addEventListener('message', this._onReceiveMessage);
    this.worker = worker;
  }

  componentWillUnmount() {
    this._removeWorker();
  }

  render() {
    return (
      <div className={`${characterRendererContainerStyle}`} ref={element => this.refCanvasContainer = element} />
    )
  }
}

CharacterThreeScene.defaultProps = {
  canvasSize: 256,
}

let characterRendererContainerStyle = css({
  label: 'characterRendererContainer',
  display: 'block',
  pointerEvents: 'none'
});


export default CharacterThreeScene