import React, { Component } from 'react';
import { css } from 'glamor'
import Worker from 'worker-loader!../workers/animatePath.worker';
import { connect } from 'react-redux'

class AnimatedClipPath extends Component {
  constructor(props) {
    super(props);  

    this.state = {}

    this._startPathAnimationLoop = () => {
      if (!this._frameId) {
        this._frameId = window.requestAnimationFrame(this._pathAnimationLoop);
      }
    }

    this._endPathAnimationLoop = () => {
      window.cancelAnimationFrame(this._frameId);
      this._frameId = null;
    }

    this._pathAnimationLoop = () => {
      this.worker.postMessage('update');
      this._frameId = window.requestAnimationFrame(this._pathAnimationLoop);
    }

    this._onReceiveMessage = (e) => {
      if(e.data === 'finishedInitialization') {
        this._startPathAnimationLoop();
      } else {
        this.setState({path: e.data.path});
      }
    }
  }

  componentWillUnmount() {
    if (this.worker) {
      this.worker.removeEventListener('message', this._onReceiveMessage);
      this.worker.terminate();
    }
    this._endPathAnimationLoop();
  }

  componentDidUpdate(prevProps) {
    if (this.props.characterInitialized !== prevProps.characterInitialized && this.props.characterInitialized) {
      const svgSelector = this.svgContainerRef.getElementsByTagName('svg')[0];
      const pathSelector = this.svgContainerRef.getElementsByTagName('path')[0];
      const clipPathSelector = this.svgContainerRef.getElementsByTagName('clipPath')[0];

      if (svgSelector && pathSelector) {
        const worker = new Worker();
        const pathData = pathSelector.getAttribute('d');
        worker.postMessage({
          path: pathData,
          amplitude: 0.01,
          frequency: 2.5,
          precision: 5,
          clipPath: true
        });
        worker.addEventListener('message', this._onReceiveMessage);
        this.worker = worker;

        this.setState({
          viewBoxAttr: svgSelector.getAttribute('viewBox'),
          clipPathId: clipPathSelector.getAttribute('id'),
        });
      }
    }
  }

  render() {
    const { children } = this.props;
    
    return (
      <div className={`${svgContainer}`} ref={node => this.svgContainerRef = node}>
        {this.state.path ?
          <svg viewBox={this.state.viewBoxAttr}>
            <defs>
              <clipPath id={this.state.clipPathId} clipPathUnits='objectBoundingBox'>
                <path d={this.state.path}/>
              </clipPath>
            </defs>
          </svg>
        :
          children
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    characterInitialized: state.Character.initialized,
  };
};

export default connect(mapStateToProps)(AnimatedClipPath);

let svgContainer = css({
  label: 'svgContainer',
  height: '100%',
  width: '1px',
  opacity: 0,
  pointerEvents: 'none'
})