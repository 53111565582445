import React, {PureComponent} from 'react'
import { css } from 'glamor'
import { connect } from 'react-redux'
import {Motion, spring} from 'react-motion'

import CharacterDraggable from './characterDraggable';
import CharacterThreeScene from './characterThreeScene';
import { onCharacterLoaded } from '../actions'


class Character extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = {
      characterLoaded: false,
      characterScaleMultiplier: 0.33,
      characterMaxWidth: 396,
      characterMinWidth: 128,
      characterPosX: 0,
      characterPosY: 0,
      keyXpos: 0,
      keyYpos: 0,
      initialCharacterPos: {x: 33, y: -87},
      initialCharacterTranslation: {x: 0, y:0},
      characterTranslation: {x: 0, y: 0},
      lookAtTranslation: {x: 0, y: 0},
      characterLookAtDistance: {x: 0, y: 0, z: 0},
      initialZ: 100,
      lookAtPos: {x: 0, y: 0, z: 100},
      directionMultiplier: 1
    }

    let resizeTimer;

    this.onCharacterLoaded = () => {
      this.setState({characterLoaded: true});
      this.props.dispatch(
        onCharacterLoaded()
      ); 
    }

    this._calculateCanvasSize = () => {
      this.setState({canvasSize: (this.props.character && this.props.character.scale) ?
        Math.min(Math.max((window.innerWidth * this.state.characterScaleMultiplier * this.props.character.scale), this.state.characterMinWidth), this.state.characterMaxWidth)
        :
        Math.min(Math.max((window.innerWidth * this.state.characterScaleMultiplier), this.state.characterMinWidth), this.state.characterMaxWidth)
      })
    };
    
    this._updateCanvasSize = () => {
      this._calculateCanvasSize();
      if (this.props.character) {
         this.updateCharacterProperties();
      }
    }

    this._onWindowResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(
        this._updateCanvasSize, 250
      );
    };

    this._onWindowScroll = () => {
      if (this.props.character) {
        this.updateCharacterProperties();
      }
    }

    this._setInitialCharacterTranslation = () => {
      this.setState({
        initialCharacterTranslation: {
          x: this.state.initialCharacterPos.x / 100 * window.innerWidth,
          y: this.state.initialCharacterPos.y / 100 * window.innerHeight
        }
      });
    }

  
    this.updateCharacterProperties = (dragDeltaPosition) => {
      const {character, waypointOffset} = this.props;
      
      if (character && character.translate && character.lookAt) {
        const characterPixelTranslation = {
          x: (character.translate.x ? character.translate.x : 0) / 100 * window.innerWidth + ((dragDeltaPosition && dragDeltaPosition.x) ? dragDeltaPosition.x : 0),
          y: (character.translate.y ? character.translate.y : 0) / 100 * window.innerHeight + ((dragDeltaPosition && dragDeltaPosition.y) ? dragDeltaPosition.y : 0)
        };
  
        const distanceToWaypointCenter = ((window.scrollY + (window.innerHeight / 2)) - waypointOffset);
        
        const lookAtPixelTranslation = {
          x: ((character.lookAt && character.lookAt.x) ? character.lookAt.x : 0) / 100 * window.innerWidth,
          y: ((character.lookAt && character.lookAt.y) ? character.lookAt.y : 0) / 100 * window.innerHeight - distanceToWaypointCenter,
        }
        const characterLookAtDistance = {
          x: Math.round(characterPixelTranslation.x - lookAtPixelTranslation.x ),
          y: Math.round(characterPixelTranslation.y - lookAtPixelTranslation.y ),
          z: (Math.min(window.innerWidth / 1920, 1) * this.state.initialZ) * ((character && character.lookingAway) ? 1 : -1)
        }
  
        if (this.state.characterLoaded) {
          this.setState({
            characterTranslation: characterPixelTranslation,
            lookAtTranslation: lookAtPixelTranslation,
            characterLookAtDistance: characterLookAtDistance
          });
        }
      }       
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.characterLoaded !== prevState.characterLoaded) {
      this.updateCharacterProperties();
    }
  }
  
  componentDidMount() {
    window.addEventListener('resize', this._onWindowResize);
    window.addEventListener('scroll', this._onWindowScroll);

    this._setInitialCharacterTranslation();
    this._calculateCanvasSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._onWindowResize);
    window.removeEventListener('scroll', this._onWindowScroll);
  }
  
  render() {
    return (
      <div id='character' className={`${characterContainerStyle}`}>
        <div className={`${characterStyle}`}>
        <Motion style={{
          characterTranslationX: spring(this.state.characterLoaded ? this.state.characterTranslation.x : this.state.initialCharacterTranslation.x, {stiffness: 75, damping: 12}),
          characterTranslationY: spring(this.state.characterLoaded ? this.state.characterTranslation.y : this.state.initialCharacterTranslation.y, {stiffness: 75, damping: 12}),
          characterLookAtDistanceX: spring(this.state.characterLookAtDistance.x, {stiffness: 75, damping: 12}),
          characterLookAtDistanceY: spring(this.state.characterLookAtDistance.y, {stiffness: 75, damping: 12}),
          characterLookAtDistanceZ: spring(this.state.characterLookAtDistance.z, {stiffness: 7.5, damping: 12}),
          scale: spring(this.props.character && this.props.character.scale ? this.props.character.scale : 1, {stiffness: 64, damping: 16}),        }}>
          {interpolatingStyle => {
            return (
              <div className={`${characterTransformStyle}`} style={{
                transform: 'translate(' + interpolatingStyle.characterTranslationX + 'px,' + interpolatingStyle.characterTranslationY + 'px) scale(' + interpolatingStyle.scale + ')',
                WebkitTransform: 'translate(' + interpolatingStyle.characterTranslationX + 'px,' + interpolatingStyle.characterTranslationY + 'px) scale(' + interpolatingStyle.scale + ')'
              }}>
                <CharacterDraggable updateCharacterProperties={this.updateCharacterProperties}>
                  <div className={`${characterHoveringStyle}`}>
                    <CharacterThreeScene
                      characterLookAtDistance={{x: interpolatingStyle.characterLookAtDistanceX, y: interpolatingStyle.characterLookAtDistanceY, z: this.state.characterLookAtDistance.z}}
                      canvasSize = {this.state.canvasSize ? this.state.canvasSize : this.state.characterMinWidth}
                      onCharacterLoaded = {this.onCharacterLoaded}
                    />
                  </div>
                </CharacterDraggable>
              </div>
            )}}
          </Motion>                    
        </div>
      </div> 
    )
  }
}

const mapStateToProps = (state) => {
  return {
    character: state.Waypoint.character,
    waypointOffset: state.Waypoint.waypointOffset,
  };
};

export default connect(mapStateToProps)(Character);

let characterContainerStyle = css({
  label: 'characterContainer',
  position: 'fixed',
  pointerEvents: 'none',
  height: '100vh',
  width: '100%',
  left: 0,
  top: 0,
  zIndex: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

let characterStyle = css({
  label: 'character',
  pointerEvents: 'none',
});

let characterTransformStyle = css({
  label: 'characterTransform',
  display: 'block'
});

let hoveringAnimation = css.keyframes({  
  '0%': { transform: 'translate(0,  0px)'}, 
  '50%': { transform: 'translate(0, 12px)'}, 
  '100%': { transform: 'translate(0, -0px)' } 
})

let characterHoveringStyle = css({
  label: 'characterHovering',
  animation: `${hoveringAnimation} 3s infinite`, 
  animationTimingFunction: 'ease-in-out'
})
